import React from 'react'
import styled from 'styled-components';
import { FaEnvelope, FaPhone } from 'react-icons/fa';

import t from '../i18n/translate';
import { loadScript } from '../utils';

const Footer = styled.div`
  font-family: 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  color: #444;
  text-align: center;
  margin-bottom: 3em;
`

const Name = styled.div`
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid #ccc;
  display: inline-block;
  line-height: 2em;
`
const Contacts = styled.div`
  line-height: 2em;
  a {
    display: inline-block;
    color: #444;
    padding: 0 1em;
  }
  margin-bottom: 1.5rem;
`
const Wrapper = styled.div`
  display: inline-block;
  padding: 0 2em;
  svg {
    transform: translateY(2px);
  }
`
const Info = styled.div`
  display: none;
  margin-bottom: 1em;
`

const Privacy = styled.a`
  text-decoration: underline;
  color: #444;
`

export default class extends React.Component {
  componentDidMount() {
    loadScript('https://cdn.iubenda.com/iubenda.js');
  }

  render() {
    return (
      <Footer>
        <Name>{t('common.dottssa')} {t('common.vale')} - {t('common.titolo')}</Name>
        <Contacts>
          <Wrapper>
            <FaEnvelope />
            <a href={`mailto:${t('common.email')}`}>{t('common.email')}</a>
          </Wrapper>
          <Wrapper>
            <FaPhone />
            <a href={`tel:${t('common.telValue')}`}>{t('common.telText')}</a>
          </Wrapper>
        </Contacts>
        <Privacy href='https://www.iubenda.com/privacy-policy/76237139' className='iubenda-white iubenda-embed ' title='Privacy Policy '>{t('common.privacy')}</Privacy>
        <Info>
          P.IVA: N/A
        </Info>
      </Footer>
    );
  }
}
