import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Stage from '../page-components/stage';
import ContactUs from '../page-components/contactus';
import Footer from '../page-components/footer';

export default () => (
  <Layout>
    <SEO title="Dott.ssa Valentina Chigliaro" keywords={['Psicologa', 'Psicoterapeuta', 'Valentina', 'Chigliaro']} />
    <Stage />
    <ContactUs />
    <Footer />
  </Layout>
);
