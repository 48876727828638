import React from 'react'
import styled from 'styled-components';
import { Form, Text, TextArea, Checkbox } from 'informed';
import { FaSpinner, FaRegSmileBeam, FaTimesCircle, FaCheckCircle } from 'react-icons/fa';

import t from '../i18n/translate';

const submissionUrl = 'https://europe-west1-ef-workshop.cloudfunctions.net/sendEmail/';

const ContactUs = styled.div`
  font-family: 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;
  font-size: 20px;
  @media (min-width: 768px) {
    font-size: 24px;
    max-width: 400px;
  }
  @media (min-width: 1440px) {
    font-size: 28px;
  }
  margin: 3rem auto 0;
  text-align: center;
  max-width: 90vw;

  form {
    position: relative;
  }
`

const Row = styled.div`
  position: relative;
  max-width: 400px;
  margin: 0 auto .5rem;

  input, textarea {
    width: 100%;
    border: 1px solid #d0d0d0;
    font-size: .8em;
    padding: .75rem 2rem 0 .75rem;
    height: 2.7rem;

    &:focus {
      border-color: #24afe7;
    }

    & + label {
      position: absolute;
      left: .75rem;
      font-size: .8em;
      color: #777;
      top: .6rem;
      transition: 0.2s ease all;
    }

    &:focus + label,
    &:not(.empty) + label {
      top: 0;
      left: .85rem;
      font-size: .5em;
    }
  }

  textarea {
    min-height: 6rem;
    padding-top: 1rem;
  }
`

const Privacy = styled.div`
  position: relative;
  font-size: .9rem;
  text-align: left;
  margin-bottom: 1rem;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;

    & + label {
      display: inline-block;
      padding-left: 1.8rem;
      cursor: pointer;
      user-select: none;

      a {
        color: #444;
        font-weight: bold;
      }

      &::before {
        content: '';
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 3px;
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid #bbb;
        background-color: #fff;
        transition: 0.4s ease background-color;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 3px;
        opacity: 0;
        transition: 0.4s ease opacity;
      }
    }

    &:checked + label::before {
      background-color: #999;
    }

    &:checked + label::after {
      content: '';
      position: absolute;
      left: 5px;
      top: 12px;
      background: white;
      width: 2px;
      height: 2px;
      opacity: 1;
      box-shadow:
        2px 0 0 white,
        4px 0 0 white,
        4px -2px 0 white,
        4px -4px 0 white,
        4px -6px 0 white,
        4px -8px 0 white;
      transform: rotate(45deg);
    }
  }
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  font-size: .6em;
  text-align: left;
  opacity: 0;
  transition: .2s ease all;

  &:not(:empty) {
    opacity: 1;
  }
`;

const ValidationIcon = styled.div`
  display: none;
  font-size: 1rem;
  position: absolute;
  color: #28a745;
  width: 20px;
  height: 20px;
  top: 14px;
  right: 6px;

  &.touched {
    display: block;
  }

  &.invalid {
    color: #dc3545;
  }
`;

const Submit = styled.button`
  border: none;
  border-radius: 1em;
  background-color: #ffe456;
  color: #000;
  text-shadow: none;
  padding: .5em 2em;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  outline: none;

  &:hover {
    background-color: #e2cc58;
  }
`

const Overlay = styled.div`
  position: absolute;
  background-color: rgba(255,255,255,.75);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
            animation: icon-spin 2s infinite linear;
  }

  .icon-pulse {
    -webkit-animation: icon-spin 1s infinite steps(8);
          animation: icon-spin 1s infinite steps(8);
  }

  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }

  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
`;

const ThankYou = styled.div`
  position: absolute;
  background-color: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  line-height: 1.3;

  svg {
    color: #28a745;
  }
`

const validate = (name, value, { required, pattern }) => {
  if (required && !value) {
    return t('index.contactus.required');
  }

  if (value && pattern && !pattern.test(value)) {
    if (name === 'email') {
      return t('index.contactus.invalidEmail');
    }
  }

  return null;
};

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      submitted: false,
    };

    this.submit = this.submit.bind(this);
    this.setFormApi = this.setFormApi.bind(this);
  }

  setFormApi(formApi) {
    this.formApi = formApi;
  }

  submit() {
    const { invalid, pristine, values } = this.formApi.getState();

    if (!pristine && !invalid) {
      this.setState({
        submitting: true,
      });
      fetch(submissionUrl, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
        // body: JSON.stringify({debugRob: true}),
      })
        .then(() => {
          this.setState({
            submitting: false,
            submitted: true,
          });
        })
        .catch((err) => {
          this.setState({
            submitting: false,
          });
        })
    }
  }

  render() {
    return (
      <ContactUs id="contactus">
        <h2>{t('index.contactus.title')}</h2>
        <h4>{t('index.contactus.subtitle')}</h4>
        <Form getApi={this.setFormApi}>
          {({ formState }) => {
            const {
              firstName,
              email,
              tel,
              skype,
              request,
            } = formState.values;

            const fields = [
              {
                name: 'firstName',
                value: firstName,
                autofill: 'name',
                validation: {
                  required: true,
                  pattern: /\w*/,
                },
              },
              {
                name: 'email',
                value: email,
                autofill: 'email',
                validation: {
                  required: true,
                  // eslint-disable-next-line
                  pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                },
              },
              {
                name: 'tel',
                value: tel,
                autofill: 'tel',
                validation: {
                  pattern: /\w*/,
                },
              },
              {
                name: 'skype',
                value: skype,
                validation: {
                  pattern: /\w*/,
                },
              },
              {
                type: 'textarea',
                name: 'request',
                value: request,
                autofill: 'off',
                validation: {
                  required: true,
                  pattern: /\w*/,
                },
              },
            ];

            const { submitting, submitted } = this.state;

            return (
              <div>
                {
                  fields.map(({ name, value, validation, type }) => (
                    <Row key={name}>
                      {
                        type && type === 'textarea'
                        ? <TextArea
                            field={name}
                            id={name}
                            className={value ? '' : 'empty'}
                            validateOnBlur
                            validate={(value) => validate(name, value, validation)}
                          />
                      : <Text
                          field={name}
                          id={name}
                          className={value ? '' : 'empty'}
                          validateOnBlur
                          validate={(value) => validate(name, value, validation)}
                        />
                      }
                      <label htmlFor={name}>{t(`index.contactus.${name}`)}</label>
                      <ValidationIcon className={`${formState.touched[name] ? 'touched' : ''} ${formState.errors[name] ? 'invalid' : ''}`}>
                        {
                          formState.errors[name]
                          ? <FaTimesCircle />
                          : <FaCheckCircle />
                        }
                      </ValidationIcon>
                      <ErrorMessage>{formState.errors[name]}</ErrorMessage>
                    </Row>
                  ))
                }
                <Privacy>
                  <Checkbox
                    field="privacy"
                    id="privacy"
                    validateOnChange
                    validate={(value) => validate('privacy', value, { required: true })}
                  />
                  <label htmlFor="privacy">
                    {t('index.contactus.privacyText')}&nbsp;
                    <a href="https://www.iubenda.com/privacy-policy/76237139" target="_blank" rel="noopener noreferrer">
                      {t('index.contactus.privacyLink')}
                    </a>
                  </label>
                  <ValidationIcon className={`${formState.touched.privacy ? 'touched' : ''} ${formState.errors.privacy ? 'invalid' : ''}`}>
                    {
                      formState.errors.privacy
                      ? <FaTimesCircle />
                      : <FaCheckCircle />
                    }
                  </ValidationIcon>
                </Privacy>
                <Submit type="submit" onClick={this.submit}>{t('index.contactus.submit')}</Submit>
                {
                  submitting && (
                    <Overlay>
                      <FaSpinner className="icon-pulse" />
                    </Overlay>
                  )
                }
                {
                  submitted && (
                    <ThankYou>
                      <FaRegSmileBeam />
                      <br/>
                      {t('index.contactus.thankyou1')}
                      <br/>
                      {t('index.contactus.thankyou2')}
                    </ThankYou>
                  )
                }
              </div>
            );
          }}
        </Form>
      </ContactUs>
    );
  }
}
