export const loadScript = (src) => {
  const loader = () => {
    const s = document.createElement('script');
    const tag = document.getElementsByTagName('script')[0];
    s.src = src;
    tag.parentNode.insertBefore(s, tag);
  };
  if (window.document.readyState === 'complete') {
    loader();
  } else  if (window.addEventListener) {
    window.addEventListener('load', loader, false);
  } else if (window.attachEvent) {
    window.attachEvent('onload', loader);
  } else {
    window.onload = loader;
  }
}
