import IT from './it.json';

export default function t(str = 'undefined', obj = IT) {
  const splitted = str.split('.');
  if (splitted.length > 1) {
    const key = splitted.shift();
    return t(splitted.join('.'), obj[key]);
  } // if

  if (obj[splitted[0]]) {
    return obj[splitted[0]];
  } // if

  console.log(`%cNo translation found for "${str}"`, 'color: #f4a742'); // eslint-disable-line no-console
  return null;
}
