import React from 'react'
import styled from 'styled-components';

import t from '../i18n/translate';

const containerHeight = '86vh';

const Stage = styled.div`
  @media (min-width: 768px) {
    font-size: 24px;
    height: 100vh;
  }
  @media (min-width: 1440px) {
    font-size: 36px;
  }
  font-family: 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;
  font-size: 14px;
  color: #fff;
  text-shadow: 2px 2px 4px #000;
  width: 100vw;
  height: ${containerHeight};
  background-image: url(https://res.cloudinary.com/snk-cloud-images/image/upload/v1539960667/valentina-chigliaro/layout/whoami.jpg);
  background-size: cover;
  background-position: center;
`

const Overlay = styled.div`
  width: 100vw;
  height: ${containerHeight};
  background-color: rgba(0,0,0,.2);
  background-size: cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  @media (min-width: 768px) {
     height: 100vh;
  }
`

const Headline = styled.div`
  font-size: 2.2em;
  line-height: 1em;
  text-align: center;
  flex: 5 5 auto;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  @media (min-width: 450px) {
    flex-direction: row;
    align-items: flex-end;
  }
`
const Line = styled.span`
  margin: 0 auto;
  display: ${props => props.mobile ? 'block' : 'none'};
  @media (min-width: 450px) {
    display: ${props => props.desktop ? 'inline' : 'none'};
  }
`
const Subtitle = styled.div`
  font-size: 1.8em;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
`
const Description = styled.div`
  font-size: 1.25em;
  flex: 1 1 auto;
  display: flex;
  align-items: flex-start;
  text-align: center;
  line-height: 1.2em;
`
const CTAWrapper = styled.div`
  display: inline-block;
  flex: 2 2 auto;
`
const CTA = styled.a`
  border-radius: 1em;
  background-color: #ffe456;
  color: #000;
  text-shadow: none;
  padding: .5em 2em;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #e2cc58;
  }
`

const ArrowWrapper = styled.div`
  flex: 1 1 none;
  padding-bottom: 2rem;
  cursor: pointer;
`

const DownArrow = styled.a`
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
`

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.goto = this.goto.bind(this);
  }

  goto(ev) {
    ev.preventDefault();
    const href = ev.target.getAttribute('href');

    document.querySelector(href).scrollIntoView({
      behavior: 'smooth'
    });
  }

  render() {
    return (
      <Stage>
        <Overlay>
          <Headline>
            <Line mobile>{t('common.dottoressa')}&nbsp;</Line>
            <Line desktop>{t('common.dottssa')}&nbsp;</Line>
            <Line mobile desktop>{t('common.vale')}</Line>
          </Headline>
          <Subtitle>{t('common.titolo')}</Subtitle>
          <Description>{t('common.where1')}<br />{t('common.where2')}</Description>
          <CTAWrapper>
            <CTA href="#contactus" onClick={this.goto}>
              {t('index.stage.contattami')}
            </CTA>
          </CTAWrapper>
          <ArrowWrapper>
            <DownArrow href="#contactus" onClick={this.goto} />
          </ArrowWrapper>
        </Overlay>
      </Stage>
    );
  }
}
